import * as React from 'react';
import Helmet from 'react-helmet';

const ThankYouPage: React.FC = () => {

  return (
    <>
      <Helmet title="Confirmation" />
      <section className="confirmation spacing-bottom--top section--about">
        <div className="inner inner-padding">
          <h2 className="text-center">Thank you!</h2>
          {/* <p className="text-center text--intros">BlueValue is a project of the Socio-Economics Group at the Harte Research Institute for Gulf of Mexico Studies at Texas A&amp;M University-Corpus Christi.</p> */}

          <div className="pt-12 contact--transparent text-center">
            <div className="paperplane my-4 mx-auto"></div>
            <p className="confirmation-text text-xl lightwhiteblue mx-2 lg:mx-32 mb-8">Your message has been sent!</p>
            <p className="mx-6 lg:mx-32 mb-12 text-lg lg:text-xl">Thank you for getting in touch, our team will be in contact with you soon! For urgent enquiries please email <a href="mailto:seg-hri@tamucc.edu">seg-hri@tamucc.edu</a></p>
            <a href="/contact"className="button btn btn-green px-8 lg:px-12 py-5 mt-12">Send another message</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYouPage;
